import axios from 'axios';
import { getEnvPath } from './env';

const handleError = (error: Error): void => {
    // custom error handling comes here
    console.error(error);
};

const cassieUrl = (): string => {
    const env = getEnvPath();
    return process.env.REACT_APP_CASSIE_BASE_URL ? process.env.REACT_APP_CASSIE_BASE_URL.replace('{env}', env) : '';
};

export const getCassieConfig = async () => {
    const apiCall = axios.create({ baseURL: cassieUrl(), timeout: 15000 });
    return apiCall
        .get('/config.json')
        .then(({ data }) => data)
        .catch(handleError);
};

export const getSdkPath = async () => {
    const config = await getCassieConfig();
    if (config) {
        return config;
    } else {
        return null;
    }
};
