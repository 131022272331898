import FormError from 'components/FormError/FormError';
import React, { Component } from 'react';
import Tooltip from '../Tooltip/Tooltip';

import './InputField.scss';

interface InputFieldProps {
    inputType: string;
    inputId: string;
    inputValue: string | number;
    classNames?: string;
    labelText: string;
    handleChange?: Function;
    autocomplete?: boolean;
    error?: string;
    showHide?: boolean;
    tooltip?: string;
    tooltipTitle?: string;
    blurValidation?: any;
    placeholder?: string;
    min?: number;
    max?: number;
}

class InputForm extends Component<InputFieldProps> {
    state = {
        hidden: true,
    };

    _toggleVisibility = (e: any) => {
        e.preventDefault();
        this.setState({ hidden: !this.state.hidden });
    };

    render() {
        const {
            inputType,
            inputId,
            inputValue,
            classNames = '',
            labelText,
            handleChange = () => {},
            error = '',
            showHide = false,
            tooltip = '',
            tooltipTitle = '',
            blurValidation = () => {},
        } = this.props;
        const { hidden } = this.state;
        return (
            <div className={`form-input-group ${classNames}`}>
                <label className="input-label" htmlFor={inputId}>
                    {labelText}
                </label>
                <input
                    className={'input-field ' + inputType}
                    id={inputId}
                    name={inputId}
                    autoComplete="off"
                    type={hidden ? inputType : 'text'}
                    value={inputValue}
                    onChange={(e: any) => handleChange(e)}
                    onBlur={(e: any) => blurValidation(e)}
                    {...this.props}
                />
                {showHide && (
                    <input
                        className={`show-hide-icon ${!hidden ? 'visible' : ''}`}
                        onClick={this._toggleVisibility}
                        type="text"
                        defaultValue="show or hide"
                    />
                )}
                {tooltip && <Tooltip header={tooltipTitle || labelText} tooltipText={tooltip} />}
                {error && <FormError error={error} />}
            </div>
        );
    }
}

export default InputForm;
