const getEnvPath = (): string => {
    const hostname = window && window.location ? window.location.hostname : '';
    let env = 'test';
    if (hostname.includes('my5.tv') || hostname.includes('channel5.com')) {
        env = hostname.split('.')[0].replace('activate-', '');
        if (!['staging', 'test'].includes(env)) env = '';
    }
    return !env || env === '' ? '' : `-${env}`;
};

const getEnvName = (): string => {
    const env = getEnvPath();
    switch (env) {
        case '-test':
            return 'test';
        case '-staging':
            return 'staging';
        default:
            return 'prod';
    }
};

export enum Environments {
    Test = 'test',
    Staging = 'staging',
    Prod = 'prod',
}

enum DesktopAppPortalDomains {
    Local = 'http://localhost:3000',
    Test = 'https://test.channel5.com',
    Staging = 'https://staging.channel5.com',
    Prod = 'https://channel5.com',
}

const desktopUrl = (): string => {
    const url = window.location.origin;

    if (url.includes('localhost')) {
        return DesktopAppPortalDomains.Local; // local activate url
    } else if (url.includes(Environments.Test)) {
        return DesktopAppPortalDomains.Test;
    } else if (url.includes(Environments.Staging)) {
        return DesktopAppPortalDomains.Staging;
    }

    return DesktopAppPortalDomains.Prod;
};

export { getEnvPath, getEnvName, desktopUrl };
