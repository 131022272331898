import React from 'react';
import { Check } from './Check';

import './ForgotPassword.scss';

interface MailSentProps {
    email: string;
    close: () => void;
}

const MailSent: React.FC<MailSentProps> = ({ email, close }) => {
    return (
        <div className="passwordSentWrapper">
            <input className="icon close" type="button" onClick={close} />
            <Check />
            <div className="passwordSent" data-testid="password-reset-title">
                Password Reset
            </div>
            <p className="passwordSentText">
                If {email} matches an active account, we will send you an email containing instructions on how to reset
                your password.
            </p>
            <p className="passwordSentText">You may have to wait a few minutes for the email.</p>
            <p className="passwordSentText">
                If you can’t find the email, please check your spam and deleted items folders. Please act on the email
                straight away.
            </p>
            <button onClick={close} className="passwordSentClose">
                Ok
            </button>
        </div>
    );
};

export default MailSent;
