import React from 'react';
import { Helmet } from 'react-helmet';

import Signin from 'containers/Signin/Signin';
import Register from 'containers/Register/Register';
import ForgotPassword from 'containers/ForgotPassword/ForgotPassword';
import Pin from 'containers/Pin/Pin';
import ResultScreen from 'containers/ResultScreen/ResultScreen';
import ChangePassword from 'containers/ChangePassword/ChangePassword';
import { Screens } from 'containers/constants';
import { initTracking } from 'utils';
import { getSdkPath } from 'utils/api';

// import MyData from './containers/MyData/MyData';

import './App.scss';
import UserService from 'utils/userService';
import Footer from 'components/Footer/Footer';
import style from './App.module.scss';
import clsx from 'clsx';

declare global {
    interface Window {
        _satellite: any;
        c5UserService: any;
    }
}

interface Props {}
interface State {
    screen: Screens;
    reset: { code?: string; email?: string } | null;
    newAccount: boolean;
    userName: string;
    sdkUrl?: string;
    pin?: string;
    isLoggedInSSO: boolean | null;
    renderSSO: boolean;
    token: string;
    ssoHasRun: boolean;
    ssoMaxTries: number;
    appSettings: Record<string, unknown>;
}

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            screen: Screens.SIGNIN,
            reset: null,
            newAccount: false,
            userName: 'user',
            sdkUrl: undefined,
            pin: undefined,
            isLoggedInSSO: null,
            renderSSO: false,
            token: '',
            ssoHasRun: false,
            ssoMaxTries: 3,
            appSettings: {},
        };
    }

    async componentDidMount() {
        this.checkParams();

        initTracking();
        const config = await getSdkPath();
        this.setState({ sdkUrl: config.userServiceSettings.sdkUrl, appSettings: config.appFeatureSettings });
        window._satellite && window._satellite.track('PAGE_VIEW');
    }

    checkParams() {
        const url = new URLSearchParams(window.location.search);
        window.history.replaceState({}, document.title, '/');

        url.get('sso') &&
            this.setState({
                renderSSO: !!url.get('sso') && !!this.state.appSettings.useSSO,
            });

        url.get('code') &&
            this.setState({
                reset: {
                    code: url.get('code') || undefined,
                    email: url.get('email') || undefined,
                },
            });

        url.get('pin') &&
            this.setState({
                pin: url.get('pin') || undefined,
            });
    }

    // PS. Works only "assuming" that SDK Script has loaded.
    _handleUserUpdate = async () => {
        const userService = new UserService();
        const result = await userService.getUserInfo();
        this.setState({
            userName: result ? result.firstName : null,
        });
    };

    _setUserToken = async (token: string) => {
        if (token.length > 1) {
            localStorage.setItem('My5_SessionId', token);
            this.setState({ token });
        }
    };

    _handleScreen = (screen: Screens, newAccount: boolean = false) => {
        this.setState({
            screen: screen,
            reset: null,
            newAccount: newAccount || this.state.newAccount,
        });
    };

    _handleLogout = () => {
        this.setState({
            isLoggedInSSO: false,
        });
    };

    render() {
        const { screen, reset, newAccount, userName, sdkUrl, pin } = this.state;

        return (
            <>
                {sdkUrl && (
                    <Helmet>
                        <script src={sdkUrl} type="text/javascript" />
                    </Helmet>
                )}

                <div className="my5_logo"></div>
                {screen && ![Screens.PIN_SCREEN, Screens.RESULT_SCREEN, Screens.MY_DATA].includes(screen!) ? (
                    <div className={clsx('app', screen === Screens.REGISTER ? 'register' : '')}>
                        {![Screens.FORGOT_PASSWORD, Screens.SOCIAL_LOGIN].includes(screen) && !reset && (
                            <>
                                <div className={clsx('headers', screen === Screens.REGISTER ? 'register' : '')}>
                                    <button
                                        className={`link header ${screen === Screens.SIGNIN ? 'active' : ''}`}
                                        onClick={() => this._handleScreen(Screens.SIGNIN)}
                                    >
                                        Sign in
                                    </button>
                                    <button
                                        className={`link header ${screen === Screens.REGISTER ? 'active' : ''}`}
                                        onClick={() => this._handleScreen(Screens.REGISTER)}
                                    >
                                        Register
                                    </button>
                                </div>
                            </>
                        )}
                        {[Screens.FORGOT_PASSWORD, Screens.SOCIAL_LOGIN].includes(screen) && (
                            <ForgotPassword
                                socialLogin={screen === Screens.SOCIAL_LOGIN}
                                handleScreen={this._handleScreen}
                            />
                        )}
                        {screen === Screens.SIGNIN && reset && (
                            <ChangePassword code={reset.code!} email={reset.email} handleScreen={this._handleScreen} />
                        )}
                        {screen === Screens.SIGNIN && !reset && (
                            <div className="signin-screen">
                                <Signin handleScreen={this._handleScreen} handleUserUpdate={this._handleUserUpdate} />
                            </div>
                        )}
                        {screen === Screens.REGISTER && (
                            <div className="register-screen">
                                <Register handleScreen={this._handleScreen} handleUserUpdate={this._handleUserUpdate} />
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        {screen === Screens.PIN_SCREEN && (
                            <Pin
                                pins={pin ? pin.split('') : undefined}
                                name={userName}
                                newAccount={newAccount}
                                handleScreen={this._handleScreen}
                                onLogout={this._handleLogout}
                            />
                        )}
                        {screen === Screens.RESULT_SCREEN && (
                            <ResultScreen
                                name={userName}
                                handleScreen={this._handleScreen}
                                useSSO={!!this.state.appSettings.useSSO}
                            />
                        )}
                    </>
                )}
                {screen !== Screens.PIN_SCREEN && screen !== Screens.RESULT_SCREEN && (
                    <Footer className={style.footer} />
                )}
            </>
        );
    }
}

export default App;
