import React from 'react';

export const Check: React.FC<{}> = () => {
    return (
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6223_30662)">
                <path
                    d="M18.1667 27.6667C17.4167 27.6667 16.6667 27.3333 16.0833 26.8333L9.66667 20.4167C8.5 19.25 8.58334 17.4167 9.66667 16.3333C10.25 15.75 11 15.5 11.75 15.5C12.5 15.5 13.25 15.8333 13.8333 16.3333L18.1667 20.75L34.4167 4.58333C35.5833 3.49999 37.4167 3.49999 38.5 4.58333C39.0833 5.16666 39.3333 5.83333 39.3333 6.66666C39.3333 7.41666 39 8.16666 38.5 8.74999L20.25 26.8333C19.6667 27.4167 19 27.6667 18.1667 27.6667ZM11.8333 16.25C11.25 16.25 10.75 16.5 10.4167 16.8333C9.58334 17.6667 9.58334 18.9167 10.4167 19.75L16.8333 26.1667C17.25 26.5833 17.75 26.75 18.25 26.75C18.8333 26.75 19.3333 26.5 19.6667 26.1667L37.9167 8.08333C38.3333 7.66666 38.5 7.16666 38.5 6.66666C38.5 6.08333 38.25 5.58333 37.9167 5.16666C37.0833 4.33333 35.8333 4.33333 35 5.16666L18.5 21.5833C18.3333 21.75 18.0833 21.75 17.9167 21.5833L13.25 16.9167C12.9167 16.5 12.3333 16.3333 11.8333 16.25Z"
                    fill="#FED141"
                />
                <path
                    d="M20.5833 39.9167C20 39.9167 19.4166 39.9167 18.75 39.8333C13.4166 39.3333 8.58328 36.8333 5.16662 32.75C1.74995 28.6667 0.0832832 23.5 0.583283 18.1667C1.58328 7.16667 11.3333 -0.916665 22.3333 1.53482e-06C25.0833 0.250002 27.75 1.08333 30.1666 2.41667C30.3333 2.5 30.4166 2.75 30.3333 3C30.25 3.16667 30 3.25 29.75 3.16667C27.4166 1.91667 24.9166 1.08333 22.25 0.833335C11.75 -0.0833318 2.41662 7.75 1.49995 18.25C0.99995 23.3333 2.58328 28.3333 5.83328 32.25C9.08328 36.1667 13.75 38.5833 18.8333 39.0833C29.3333 40 38.6666 32.1667 39.6666 21.6667C39.9166 19.0833 39.5833 16.5833 38.8333 14.0833C38.75 13.8333 38.9166 13.6667 39.0833 13.5833C39.3333 13.5 39.5 13.6667 39.5833 13.8333C40.4166 16.4167 40.6666 19 40.4166 21.75C39.5 32.0833 30.75 39.9167 20.5833 39.9167Z"
                    fill="white"
                    fillOpacity="0.92"
                />
            </g>
            <defs>
                <clipPath id="clip0_6223_30662">
                    <rect width="40" height="40" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
