import React from 'react';
import './FormError.scss';

interface FormErrorProps extends React.HTMLAttributes<HTMLDivElement> {
    error: string;
}
const FormError: React.FC<FormErrorProps> = ({ error, ...rest }) => (
    <div className="formError" {...rest}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.3335 9.99998H8.66683V11.3333H7.3335V9.99998ZM7.3335 4.66665H8.66683V8.66665H7.3335V4.66665ZM7.9935 1.33331C4.3135 1.33331 1.3335 4.31998 1.3335 7.99998C1.3335 11.68 4.3135 14.6666 7.9935 14.6666C11.6802 14.6666 14.6668 11.68 14.6668 7.99998C14.6668 4.31998 11.6802 1.33331 7.9935 1.33331ZM8.00016 13.3333C5.0535 13.3333 2.66683 10.9466 2.66683 7.99998C2.66683 5.05331 5.0535 2.66665 8.00016 2.66665C10.9468 2.66665 13.3335 5.05331 13.3335 7.99998C13.3335 10.9466 10.9468 13.3333 8.00016 13.3333Z"
                fill="#D91F00"
            />
        </svg>
        {error}
    </div>
);

export default FormError;
