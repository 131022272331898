import React from 'react';
import { Screens } from 'containers/constants';
import ResultSvg from '../../styles/assets/result.svg';
import UserService from 'utils/userService';
import { desktopUrl } from 'utils/env';

import './ResultScreen.scss';

interface IProps {
    name?: string;
    handleScreen: Function;
    useSSO: boolean;
}

export default class ResultScreen extends React.Component<IProps> {
    componentDidMount(): void {
        setTimeout(() => {
            const url = this.props.useSSO
                ? `${desktopUrl()}/sso?sessionId=${btoa(localStorage.getItem('My5_SessionId') || '')}`
                : desktopUrl();
            window.location.replace(url);
        }, 5000);
    }
    _handlePinButton = () => {
        this.props.handleScreen(Screens.PIN_SCREEN, true);
    };

    _handleOpenDesktopSite = () => {
        const url = this.props.useSSO
            ? `${desktopUrl()}/sso?sessionId=${btoa(localStorage.getItem('My5_SessionId') || '')}`
            : desktopUrl();
        window.location.replace(url);
    };

    _handleLoginButton = async () => {
        const userService = new UserService();
        await userService.signOut();
        this.props.handleScreen(Screens.SIGNIN, false);
    };

    render() {
        return (
            <div className="result-screen">
                <div className="container">
                    <div className="image-container">
                        <img alt="Successful" src={ResultSvg} />
                    </div>
                    <div className="title">Thank you, {this.props.name || ''}!</div>
                    <div className="note">
                        You have paired a new device with your My5 account and can now access new features, enjoy!
                    </div>
                    <div className="login-button">
                        <button className="loginButton" onClick={this._handleOpenDesktopSite}>
                            Go to Channel5.com
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
