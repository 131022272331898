/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Screens, PIN_TEXT_NEW_ACCOUNT, PIN_TEXT_EXISTING_ACCOUNT, PIN_ERROR_INVALID } from 'containers/constants';
import UserService from 'utils/userService';
import OkSvg from 'styles/assets/ok.svg';

import './Pin.scss';
import FormError from 'components/FormError/FormError';

interface IState {
    pins: any[];
    isLoading: boolean;
    responseError: string;
}

interface IProps {
    pins?: string[];
    name?: string;
    newAccount: boolean;
    handleScreen: Function;
    onLogout?: Function;
}

const MAX_LENGTH_INDEX = 5;

export default class Pin extends React.Component<IProps, IState> {
    pinFields: any[] = [];

    state = {
        pins: this.props.pins || [],
        isLoading: false,
        responseError: '',
    };

    componentDidMount() {
        this.pinFields[0].focus();
    }

    _handleKeyDown(event: any, index: number): void {
        const code = event.which || event.keyCode;
        if (code === 8) {
            const next = index - (this.state.pins[index] ? 0 : 1);
            this.setPin('', index, next);
        } else if (code === 13 && index === MAX_LENGTH_INDEX) {
            this.sendPin();
        }
    }

    _handleChange(event: any, index: number): void {
        const pin = event.target.value;
        if (/[a-zA-Z0-9]/.test(pin) && index <= MAX_LENGTH_INDEX) {
            this.setPin(pin.toUpperCase(), index, index + 1);
        }
    }

    _handleClick(index: number) {
        this.pinFields[index].select();
    }

    setPin(key: string, index: number, next: number) {
        const pins = this.state.pins;
        pins[index] = key;
        if (index > next) {
            pins[next] = key;
        }
        this.setState({ pins }, () => {
            this.moveNext(next);
        });
    }

    moveNext(index: number) {
        if (index <= MAX_LENGTH_INDEX && index >= 0) {
            this.pinFields[index].focus();
            this.pinFields[index].select();
        }
    }

    checkPin() {
        return !this.state.isLoading && this.state.pins.join('').length === MAX_LENGTH_INDEX + 1;
    }

    logout = async () => {
        const userService = new UserService();
        await userService.signOut();
        this.props.handleScreen(Screens.SIGNIN, false);
        this.props.onLogout && this.props.onLogout();
    };

    sendPin() {
        this.setState({ isLoading: true }, async () => {
            try {
                const pin = this.state.pins.join('').toLowerCase();
                if (pin.length === MAX_LENGTH_INDEX + 1) {
                    const userService = new UserService();
                    const result: any = await userService.activatePin(pin);
                    if (result === undefined || result.errorCode) {
                        this.setState({
                            responseError: result ? result.message : PIN_ERROR_INVALID,
                        });
                    } else {
                        this.props.handleScreen(Screens.RESULT_SCREEN);
                    }
                }
            } catch (e) {
                this.setState({ responseError: (e as any).message });
            } finally {
                this.setState({ isLoading: false });
            }
        });
    }

    content(): any {
        let pins = [];
        for (let i = 0; i <= MAX_LENGTH_INDEX; i++) {
            pins.push(
                <div className="pinField" key={i}>
                    <input
                        type="text"
                        ref={(input) => (this.pinFields[i] = input)}
                        maxLength={1}
                        value={this.state.pins[i] || ''}
                        onKeyDown={(e) => this._handleKeyDown(e, i)}
                        onChange={(e) => this._handleChange(e, i)}
                        onFocus={(e) => this._handleClick(i)}
                    />
                </div>
            );
        }
        return pins;
    }

    label(): string {
        return this.props.newAccount ? PIN_TEXT_NEW_ACCOUNT : PIN_TEXT_EXISTING_ACCOUNT;
    }

    render() {
        const { responseError, isLoading } = this.state;
        return (
            <div className="pinScreen">
                <div className="container">
                    <div className="pinGradient"></div>
                    <div className="welcome">
                        <div>
                            <img alt="Successful" src={OkSvg} />
                            <span>Welcome{this.props.name ? `, ${this.props.name}` : ' to Channel 5'}</span>
                        </div>
                    </div>
                    <div className="container-inputs">
                        <div className="label title">{this.label()}</div>
                        <div className="pinContainer">
                            <div className="inputContainer">{this.content()}</div>
                            <button disabled={!this.checkPin()} onClick={() => this.sendPin()}>
                                Submit
                            </button>
                            {isLoading && (
                                <div className="spinner-container">
                                    <div className="spinner"></div>
                                </div>
                            )}
                            {responseError && <FormError error={responseError} />}
                        </div>
                    </div>
                    <div className="label contact">
                        If you experience any problems, please visit{' '}
                        <a href="https://help.channel5.com" target="_blank" rel="noopener noreferrer nofollow">
                            help.channel5.com
                        </a>
                    </div>
                    <div className="label">
                        <a href="#" onClick={this.logout}>
                            logout
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
